// main-colors
$primary: #242424;
$primary-light: #414141;
$primary-dark: #1d1d1d;
$accent: #C65A19;
$accent-light: #e16e26;
$accent-dark: #a54c15;
$secondary: #f3ebeb;
$warn: #f44336;

// enviroment
$bg_lv-2: #6d6d6d;
$bg_lv-1: #9b9b9b;
$bg_lv0: #bebebe;
$bg_lv1: #d9d9d9;
$bg_lv2: #eee;
$bg_lv2-5: #f7f7f7;
$bg_lv3: #fff;
$bg_login: #fff;
$backdrop: rgba(0, 0, 0, 0.4);
$divider: rgba(0, 0, 0, 0.12);
$divider_light: rgba(255,255, 255, 0.12);
$hover: rgba(0, 0, 0, 0.04);
$bg_success: #146c431e;
$bg_warning: #9974041e;
$bg_danger: #b02a371e;

//Types
$main_font: 'Poppins', Roboto, Sans-serif;
$chart_font: 'Martian Mono', Roboto, Sans-serif;
$text_login_color: $primary;
$text_primary: $primary;
$text_accent: $accent;
$text_dark-main: rgba(0, 0, 0, 0.87);
$text_dark-side: rgba(0, 0, 0, 0.57);
$text_light-main: rgba(255, 255, 255, 0.87);
$text_light-side: rgba(255, 255, 255, 0.57);
$text_success: #146c43;
$text_warning: #997404;
$text_danger: #b02a37;

//post-its
$p_it-orange: #f7941d;
$p_it-yellow: #fff79a;
$p_it-purple: #bc8dbf;
$p_it-green: #abd474;
$p_it-pink: #f49abf;
$p_it-cyan: #6dd0f7;

// Background Image
$background_name: "g-bk-carpincho.jpg";

// Accounts management
$account-in:#0DAA61;
$account-out:#A80B54;
