@import "_variables";

//text over color solution
@function resolveColor($color) {
  @if (lightness($color) > 50%) {
    @return #{$text_dark-main};
  }

  @else {
    @return #{$text_light-main};
  }
}

html {
  overflow: hidden !important;
}

body {
  margin: 0;
  font-family: $main_font;
  background: $bg_lv1;
  overflow: hidden;
  overscroll-behavior: none;
  height: 100vh;
}

//reset
* {
  font-family: $main_font;
}

//material hacks
button.mdc-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
}


//ajuste botones
button:not(.mdc-icon-button):not(.mdc-fab):not(.main-action):not(.filter_btn) {
  border-radius: 0 !important;
}

//ajuste area de click
.mat-mdc-button-touch-target {
  width: 100% !important;
  height: 100% !important;
}

//ajustes del modal
.mat-mdc-dialog-surface {
  min-width: 46vw !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

@mixin modal_mobile_view {
  .cdk-overlay-container {
    height: 100% !important;

    .cdk-overlay-pane {
      &.modalClass {
        width: 100vw !important;
        max-width: unset !important;

        mat-dialog-container {
          position: fixed;
          top: 0;
          width: 100vw !important;
          height: 100% !important;

          .mdc-dialog__container {
            width: 100vw;
          }
        }
      }

      &.list-form {
        width: 100vw !important;
        max-width: unset !important;

        mat-dialog-container {
          position: fixed;
          top: 0;
          width: 100vw !important;
          height: 100% !important;

          .mdc-dialog__container {
            width: 100vw;
          }
        }

        .modal_content {
          .modal_content-title {
            padding: 3vh 3vh 0;

            .main {
              line-height: 1;
            }
          }

          .modal_content-body {
            position: relative;
            height: fit-content;
            overflow: hidden;
            max-height: unset;

            .body_data {
              overflow-y: auto !important;
              padding: 0 4vmax 4vmax !important;
              height: 100% !important;
              max-height: 75vh;

              .span-1 {
                grid-column: span 2 !important;
              }

              .span-2 {
                grid-column: span 2;
              }

              .data_line {
                .line_icon {
                  display: none !important;
                }

                .line_content {
                  .line_label {
                    font-size: 1.8vh !important;
                  }

                  .line_value {
                    font-size: 2.8vh !important;
                  }
                }
              }

              mat-form-field {
                .mat-mdc-form-field-infix {
                  padding-left: 3vw !important;
                }

                .mat-mdc-form-field-icon-prefix {
                  display: none !important;
                }

                input,
                textarea,
                .mat-mdc-select-value {
                  font-size: 2.8vh !important;
                }

                mat-label {
                  font-size: 2vh !important;
                }

                .toggle_suffix {
                  flex-direction: column !important;
                  padding: 0 3vw !important;
                  row-gap: 4vw;
                  justify-content: center !important;
                }
              }

              .table-container {
                position: relative;
                overflow: hidden;
                height: max-content;
                overflow-x: auto;

                table.mat-mdc-table {
                  tr.mat-mdc-header-row {
                    height: 7vh;

                    th {
                      font-size: 2vh !important;
                    }
                  }

                  tr.mat-mdc-row {
                    height: 8vh;

                    td {
                      font-size: 2vh !important;
                      max-width: unset !important;
                    }
                  }
                }
              }

              .attachments {
                .img_scroller {
                  .img_card {
                    .img_card-title {
                      h3 {
                        font-size: 2vh !important
                      }
                    }
                  }
                }
              }

              .title {
                position: sticky;
                left: 0;

                .title_label {
                  font-size: 2vh!important;
                }

                mat-icon {
                  display: none !important;
                }
              }
            }

            .body_actions {}
          }
        }
      }
    }
  }
}

//modules
app-supplier,
app-monitor,
app-cash-monitor,
app-users,
app-customers,
app-order-report,
app-delivery,
app-dashboard,
app-conciliation,
app-orders,
app-products,
app-wallet,
app-service-payments,
app-service-managment,
app-wires,
app-new-payment,
app-product-report,
app-crud-account,
app-audit,
app-monitor-cash,
app-release-notes,
app-history-consolidate,
app-bug-report,
app-bug-report-list,
app-bug-report-detail,
app-wires,
app-expenses,
app-daily-balance,
app-producer-commission,
app-account-company,
app-accounts-managment,
app-accounts-transfer,
app-account-company-managment,
app-producers {
  display: flex;
  overflow: hidden;
  height: 100%;

  //ajuste postIts
  .clickable {
    .mdc-button__label {
      width: 100% !important;
    }

    .post-it_status,
    .post-it_description {
      .content {
        label {
          margin-top: .5vmin;
          font-weight: 400;
          font-size: 1.5vmin;
          color: rgba(0, 0, 0, .8);
        }

        span {
          font-weight: 600;
        }
      }
    }
  }

  //ajuste mobile
  .mobile_card {
    .clickable {

      .post-it_status,
      .post-it_description {
        .content {
          label {
            font-size: 1.5vmax !important;
          }
        }
      }
    }
  }

  .search_filters {
    .mdc-button .mdc-button__label {
      position: unset !important;
    }
  }

  //ajuste search
  .toolbar_search {
    padding: 1vh 1vw;
    width: 43%;
    border-radius: 4vh;
    background: $bg_lv2;
    transition: background 0.3s ease;
    transition-delay: 0.5s;

    &.focused {
      background: $bg_lv3;
      transition-delay: 0s !important;
    }

    input {
      height: 5vh;
      border: none;
      outline: none;
      background: none;
      padding: 0;
      margin-left: 1vw;
      font-size: 2vh;
      line-height: 5vh;
    }

    button.mdc-icon-button {
      height: 5vh;
      width: 5vh;
      padding: 0;

      .mat-icon {
        height: 3vh;
        width: 3vh;
        font-size: 3vh;
      }
    }

    .filter_btn {
      border-radius: 5vmin;
      padding: 0 1.5vmax;

      .mdc-button__label {
        white-space: nowrap !important;
      }


      .filter_btn-contents {
        position: relative;

        input {
          padding: 0;
          display: flex;
          background: none;
          border: none;
          margin: 0;
          width: 100%;
          outline: unset !important;
          color: $primary;
          cursor: pointer;
          text-align: center;
          font-size: 1.25vh;
        }
      }
    }

    .filters {
      width: 100%;

      .filter_btn {
        width: unset !important;
        margin: 0 !important;
        border-radius: 5vmin 0 0 5vmin !important;

        &+.filter_btn {
          border-radius: 0 5vmin 5vmin 0 !important;
        }

        input {
          font-size: 1.25vh !important
        }
      }
    }
  }
}

app-menu {
  &.off {
    .side-nav {
      margin-left: -6vw;
    }

    &+.dash_view {
      width: 100vw;
    }
  }
}

app-chapa {
  padding: 3vmax;
  display: flex;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}


@media screen and (max-width:600px) {

  body {
    height: calc(100vh - 56px) !important;
    overflow: hidden;
  }

  @include modal_mobile_view;
}

@media screen and (max-height:600px) {

  body {
    height: calc(100vh - 56px) !important;
    overflow: hidden;
  }

  @include modal_mobile_view;
}

//calendar
mat-calendar {
  max-width: 84vmin;
  height: auto !important;

  .mat-calendar-header {
    padding: 2vmax 2vmax 0;

    .mat-calendar-controls {
      margin-top: 0;
    }
  }

  .mat-calendar-content {
    padding: 0 2vmax 2vmax;
  }
}

//loader
.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #{$accent};
}


//checkboxes
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color:#{resolveColor($accent)};
  --mdc-checkbox-selected-focus-icon-color: #{$accent};
  --mdc-checkbox-selected-hover-icon-color: #{$accent};
  --mdc-checkbox-selected-icon-color: #{$accent};
  --mdc-checkbox-selected-pressed-icon-color: #{$accent};
  --mdc-checkbox-unselected-focus-icon-color: #{$text_dark-main};
  --mdc-checkbox-unselected-hover-icon-color: #{$text_dark-main};
  --mdc-checkbox-disabled-selected-icon-color: #{$text_dark-side};
  --mdc-checkbox-disabled-unselected-icon-color: #{$text_dark-side};
  --mdc-checkbox-unselected-icon-color: #{$text_dark-side};
  --mdc-checkbox-unselected-pressed-icon-color: #{$text_dark-side};
}

//sliders
.mat-mdc-slide-toggle.mat-accent {
  //normal
  --mdc-switch-selected-focus-state-layer-color: #{$accent};
  --mdc-switch-selected-handle-color: #{$accent};
  --mdc-switch-selected-hover-state-layer-color: #{$accent};
  --mdc-switch-selected-pressed-state-layer-color: #{$accent};
  //darken
  --mdc-switch-selected-focus-handle-color: #{$accent-dark};
  --mdc-switch-selected-hover-handle-color: #{$accent-dark};
  --mdc-switch-selected-pressed-handle-color: #{$accent-dark};
  //ighten
  --mdc-switch-selected-focus-track-color: #{$accent-light};
  --mdc-switch-selected-hover-track-color: #{$accent-light};
  --mdc-switch-selected-pressed-track-color: #{$accent-light};
  --mdc-switch-selected-track-color: #{$accent-light};
}

:root {
  --mdc-theme-primary:#{$primary};
  --mdc-theme-accent:#{$accent};
}
