.gigance-spinner-container {
    position: absolute;
    width: 100%;
    left: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .gigance-spinner {
        width: 10vmax;
        height: 10vmax;
        left: 50%;
        aspect-ratio: 2;
        display: grid;
        border-radius: 50%;
        background: conic-gradient($primary 25%, $primary 0 50%, $accent 0 75%, $accent 0);
        animation: s2 2s infinite linear;

        &::before,
        &::after {
            content: "";
            grid-area: 1/1;
            margin: 15%;
            border-radius: 50%;
            background: inherit;
            animation: inherit;
        }

        &::after {
            margin: 25%;
            animation-duration: 3s;
        }
    }
}

@keyframes s2 {
    100% {
        transform: rotate(1turn)
    }
}

//mobile
.mobile_view {
    .gigance-spinner-container {
        height: calc(100% - 8vmax)!important;
    }
}